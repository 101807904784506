@use 'sass:math';
@use 'mixins' as *;
@use 'functions' as *;

@include setup {
	@media print {
		@include setting(12px);
	}
}

:root {
	--space-xs: calc(#{baseline(1)} / 4);
	--space-sm: calc(#{baseline(1)} / 2);
	--space: #{baseline(1)};
	--space-md: #{baseline(2)};
	--space-lg: #{baseline(3)};
	--space-xl: #{baseline(4)};

	--weight-light: 100;
	--weight-regular: 340;
	--weight-bold: 400;
	--weight-bolder: 500;
}

@font-face {
	font-family: Josefin Sans;
	src: url(/JosefinSans-wght.tff) format(truetype);
}

body {
	font-family: Josefin Sans, OpenSans, sans-serif;
	font-weight: var(--weight-regular);
}

h1,
.h1 {
	@include scale(2);
	font-weight: var(--weight-light);
}

h2,
.h2 {
	@include scale(1);
	font-weight: var(--weight-bolder);

	// @media print {
	// 	@include scale(0);
	// }
}

h3,
.h3 {
	@include setting(17px);
	font-weight: var(--weight-bold);
}

.type-1 {
	@include scale(1);
}

.type-2 {
	@include scale(2);
}

.type-3 {
	@include scale(3);
}

.type-4 {
	@include scale(4);
}

.type-5 {
	@include scale(5);
}
