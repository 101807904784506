@use 'modern-css-reset';
@use 'reset-button' as reset;
@use 'typography';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
}

:root {
	--color-link: rgb(134, 139, 141);

	--color-success: rgb(101, 205, 182);
	--color-info: rgb(70, 162, 242);
	--color-error: rgb(240, 66, 81);
	--color-warning: rgb(247, 194, 59);

	--color-background-1: rgb(255, 255, 255);
	--color-background-2: rgb(232, 222, 247);
	--color-gray-90: rgb(216, 216, 216);
	--color-gray-79: rgb(231, 231, 231);
	--color-gray-20: rgb(67, 67, 67);
	--color-white: rgb(255, 255, 255);
	--color-black: rgb(0, 0, 0);

	--color-primary-1: #191919;
	--color-primary-2: #121212;
	--color-secondary: #9fa0ff;

	--border-radius: 2px;
	--border-radius-md: 5px;
	--border-radius-lg: 12px;
	--border-radius-xl: 24px;
}

a {
	color: inherit;
	text-decoration: none;
	color: var(--color-link);
	font-style: normal;
}

.reset-button {
	@extend %reset-button;
}

.text-error {
	color: var(--color-error);
}
