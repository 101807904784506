@use 'modularscale' as scale;
@use 'functions' as *;

@mixin setup() {
	:root {
		@include scale();

		@content;
	}
}

@mixin scale($multiplier: 0) {
	@include setting(scale.ms($multiplier));
}

@mixin setting($font-size) {
	font-size: rem($font-size) !important;
	line-height: rem(baseline(required-lines($font-size))) !important;
}
