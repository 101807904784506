@use 'styles/typography/functions' as type;
@use 'styles/breakpoints' as bp;

$gap: type.rem(88);

.top,
.main {
	padding: 0 clamp(var(--space), 10vw, $gap);

	@media screen and (min-width: bp.$dalmata) {
		padding: 0 20vw;
	}
}

.container,
.top {
	--gap: #{$gap};

	display: flex;
	flex-direction: column;
	gap: var(--gap);
}

.top {
	padding-bottom: var(--space);
	gap: type.rem(2);
}

@media print {
	.container,
	.top,
	.main {
		gap: 0;
	}

	.top {
		display: grid !important;
		grid-template-columns: 1fr 1fr;
		padding: var(--space);
	}

	.main {
		padding: 0 var(--space-md);
	}
}
